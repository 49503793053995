.sides {
    display: flex;
    flex-direction: row;
    gap: var(--arl-spacing-l);
    width: 100%;

    @include mobile-only {
        flex-direction: column;
        gap: var(--arl-spacing-m);
    }

    .sides-left {
        width: 25%;

        @include tablet-only {
            width: 35%;
        }

        @include mobile-only {
            width: 100%;
        }

        .sides-left-bloc {
            position: sticky;
            top: var(--arl-spacing-m);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--arl-spacing-m);
        }
    }

    .sides-right {
        width: 75%;

        @include tablet-only {
            width: 65%;
        }

        @include mobile-only {
            width: 100%;
        }
    }
}
