.bg-global,
.bg-universe,
.bg-roleplay,
.bg-roleplay-reverse,
.bg-celestia,
.bg-celestia-spe {
  &::after {
    content: '';
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -5;
    background-position: center;
    background-size: cover;
  }
}

.bg-block {
  &::after {
    position: absolute;
    z-index: -2;
  }
}

.bg-black {
  &::before {
    content: '';
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: var(--arl-background-black-dark);
  }
}

// --------------------------- global -----------------------------------------------

.bg-global::after {
  background-image: var(--arl-gradient-diag);
}

// --------------------------- universe -----------------------------------------------

.bg-universe::after {
  background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home01_ld.webp');
  @include mobile-only {
    background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home01_mob.png');
  }
}

// --------------------------- roleplay -----------------------------------------------

.bg-roleplay::after {
  background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home02_ld.webp');
  @include mobile-only {
    background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home02_mob.png');
  }
}

.bg-roleplay-reverse::after {
  transform: scaleX(-1);
  background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home02_ld.webp');
  @include mobile-only {
    background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home02_mob.png');
  }
}

// --------------------------- celestia -----------------------------------------------

.bg-celestia::after {
  background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home03_ld.webp');
  @include mobile-only {
    background-image: var(--arl-gradient-diag), url('../../assets/images_filled/backgrounds/home03_mob.png');
  }
}

.bg-celestia-spe::after {
  background-image: var(--arl-gradient-max-black), url('../../assets/images_filled/backgrounds/home04_ld.webp');
  @include mobile-only {
    background-image: var(--arl-gradient-max-black), url('../../assets/images_filled/backgrounds/home04_mob.png');
  }
}
