.side-menu {
  position: absolute;
  top: 0;
  max-width: 100%;
  padding: 0;
  border-top: none !important;
  border-radius: 0 0 var(--arl-radius) var(--arl-radius);
  border: var(--arl-border);
  background: var(--arl-black-color);
  overflow: auto;

  &::-webkit-scrollbar {
    height: var(--arl-spacing-s);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0 0 var(--arl-radius) var(--arl-radius);
  }

  @include mobile-only {
    border-radius: 0;

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
    }
  }

  .side-list {
    display: flex;
    flex-direction: row;

    .side-element {
      display: flex;
      padding: var(--arl-spacing-s) var(--arl-spacing-m);

      &.selected {
        background: var(--arl-secondary-color);
      }

      img {
        height: 3rem;
        width: 3rem;
        border: var(--arl-border);
        background: var(--arl-black-color);
        pointer-events: all;
        cursor: pointer;
      }
    }
  }
}
