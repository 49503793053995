@import "mixins";

// Dialog
.mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--arl-black-color) !important;
  --mdc-dialog-supporting-text-color: var(--arl-white-color) !important;
  --mdc-dialog-supporting-text-font: var(--arl-sans-serif-font);
  --mdc-dialog-supporting-text-size: var(--arl-font-size-m);
  --mdc-dialog-supporting-text-line-height: var(--arl-line-height-m);
}

.mat-mdc-dialog-component-host > h3 {
  padding: var(--arl-spacing-m) !important;
  border-bottom: var(--arl-border-white) !important;
}

.mdc-dialog__surface {
  border: var(--arl-border);
  border-radius: var(--arl-radius) !important;
}

.mdc-dialog__content {
  padding: var(--arl-spacing-m) !important;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
  padding: var(--arl-spacing-m) !important;
  border-top: var(--arl-border-white) !important;
}
