* {
  box-sizing: border-box !important;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  z-index: 1;
  font-family: var(--arl-sans-serif-font);
  font-size: var(--arl-font-size-m);
  line-height: var(--arl-line-height-m);
  color: var(--arl-white-color);
  background: var(--arl-black-color);

  // Scrollbars secondaires
  * {
    &::-webkit-scrollbar {
      height: var(--arl-spacing-s);
      width: var(--arl-spacing-s);
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: var(--arl-radius);
    }
  }
}

// Titles

h1,
.u-h1 {
  margin: 0;
  font-family: var(--arl-serif-font);
  font-size: var(--arl-font-size-xxl);
  line-height: var(--arl-line-height-xxl);
  font-weight: normal;
  text-transform: uppercase;
  color: var(--arl-light-secondary-color);

  @include mobile-only {
    text-align: center;
  }
}

h2,
.u-h2 {
  margin: 0;
  font-family: var(--arl-serif-font);
  font-size: var(--arl-font-size-xl);
  line-height: var(--arl-line-height-xl);
  font-weight: normal;
  text-transform: uppercase;
  color: var(--arl-light-secondary-color);

  @include mobile-only {
    text-align: center;
  }
}

h3,
.u-h3 {
  margin: 0;
  font-family: var(--arl-sans-serif-font);
  font-size: var(--arl-font-size-l);
  line-height: var(--arl-line-height-l);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--arl-white-color);
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0 0 0 1rem;
}

a {
  color: var(--arl-white-color);
  text-decoration: none;
}

img {
  outline: none;
  user-select: none;
}

// Scrollbar principale

::-webkit-scrollbar {
  width: var(--arl-spacing-s);
}

::-webkit-scrollbar-track {
  background: var(--arl-black-color);
}

::-webkit-scrollbar-thumb {
  background: var(--arl-white-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--arl-light-secondary-color);
}

// Inputs

input[type="file"] {
  display: none;
}

// Tables

table {
  .col-05 {
    width: 5%;
  }

  .col-10 {
    width: 10%;
  }

  .col-20 {
    width: 20%;
  }

  .col-30 {
    width: 30%;
  }

  .col-40 {
    width: 40%;
  }

  .col-50 {
    width: 50%;
  }

  .col-55 {
    width: 55%;
  }

  .col-60 {
    width: 60%;
  }

  .col-70 {
    width: 70%;
  }

  .col-80 {
    width: 80%;
  }

  .col,
  .col-100 {
    width: 100%;
  }
}
