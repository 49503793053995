.layout-view,
.layout-bloc-view,
.layout-full-view {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 15%;

  @include desktop-only {
    padding: 4rem 10%;
  }

  @include tablet-only {
    height: auto;
    width: 100%;
    padding: var(--arl-spacing-l);
  }
}

.layout-view {
  min-height: calc(100dvh - var(--arl-nav-height) * 3);
}

.layout-full-view {
  min-height: calc(100dvh - var(--arl-nav-height) * 2 - 0.1rem);
}

.layout-row {
  display: flex;
  gap: var(--arl-spacing-m);
  width: 100%;

  &.layout-col-mob {
    @include mobile-only {
      flex-direction: column;
    }
  }

  .layout-col-100 {
    width: 100% !important;
  }

  .layout-col-75 {
    width: 75% !important;
  }

  .layout-col-50 {
    width: 50% !important;
  }

  .layout-col-45 {
    width: 45% !important;
  }

  .layout-col-33 {
    width: 33.33% !important;
  }

  .layout-col-25 {
    width: 25% !important;
  }
}
