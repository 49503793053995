[separator] {
  height: var(--arl-spacing-m);
  width: 100%;
  border-top: 0.5rem solid var(--arl-secondary-color);
  border-bottom: 0.1rem solid var(--arl-light-secondary-color);
}

[white-separator] {
  position: relative;
  margin: var(--arl-spacing-m) 0;
  text-align: center;
  font-weight: bold;

  &::before {
    left: calc(2 * var(--arl-spacing-l));
  }

  &::after {
    right: calc(2 * var(--arl-spacing-l));
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    width: calc(50% - 2 * var(--arl-spacing-l) - var(--arl-spacing-m));
    border-radius: 50%;
    border: 0.1rem solid var(--arl-white-color);
    content: "";
  }
}

[comments] {
  font-style: italic;
  color: var(--arl-secondary-color);
}
