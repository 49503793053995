.grid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--arl-spacing-m);

  &.grid-sized {
    grid-auto-rows: 1fr;
  }

  &.grid-2 {
    grid-template-columns: repeat(2, 1fr);

    @include tablet-only {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.grid-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  @include tablet-only {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile-only {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-element {
    display: flex;
    width: 100%;
    padding: var(--arl-spacing-m);
    text-align: left;
    border: var(--arl-border);
    border-radius: var(--arl-radius);
    border-spacing: 0;
    background: var(--arl-background-black-dark);

    img {
      height: 4rem;
      width: 4rem;
      margin-right: var(--arl-spacing-m);
      border: var(--arl-border);
      pointer-events: none;
    }
  }
}
