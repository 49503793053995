.onglets-nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  background: var(--arl-background-black-dark);

  .onglets-nav-item {
    flex-basis: calc(100% / 3);
    padding: var(--arl-spacing-m);
    font-family: var(--arl-serif-font);
    font-weight: bold;
    text-transform: uppercase;
    color: var(--arl-secondary-color);
    border: var(--arl-border);
    cursor: pointer;

    @include mobile-only {
      flex-basis: 100%;
    }

    &.selected {
      color: var(--arl-black-color);
      background: var(--arl-secondary-color);
    }
  }
}
